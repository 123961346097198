<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

let user = JSON.parse(localStorage.getItem("user"));

/**
 * Invoice-detail component
 */
export default {
  page: {
    title: "Tạo đơn hàng mới",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      typesubmit: false,
      title: "Tạo đơn hàng mới",
      items: [
        {
          text: "Trang chủ",
          href: "/",
        },
        {
          text: "Chi tiết đơn hàng",
          active: true,
        },
      ],
      tabIndex: 1,
      order: null,
      form: {
        sender_fn: null,
        sender_phone: null,
        sender_email: null,
        sender_address: null,
        recipient_fn: null,
        recipient_phone: null,
        recipient_address: null,
        customer: null,
        invoice: null,
        products: [],
      },
    };
  },

  methods: {
    addProduct() {
      if (Array.isArray(this.form.products)) {
        this.form.products.push({
          product_name: "",
          product_price: null,
        });
      }
    },

    createNewOrder() {
      axios({
        url: process.env.VUE_APP_SERVER_URL + "/api/order/create",
        method: "post",
        headers: {
          authorization: user.token,
        },
        data: this.form,
      }).then((result) => {
        this.$toasted.success("Tạo đơn hàng mới thành công");
        this.$router.push("/");
      });
    },
  },

  created() {
    if (!this.$route.query.copy) {
      this.$router.push("/");
    }
    let _id = this.$route.query.copy;

    axios({
      url: process.env.VUE_APP_SERVER_URL + "/api/bill/info",
      method: "post",
      headers: {
        authorization: user.token,
      },

      data: {
        _id: _id,
      },
    })
      .then(({ data }) => {
        if (data) {
          this.form = {
            sender_fn: data.sender_fn,
            sender_phone: data.sender_phone,
            sender_email: data.sender_email,
            sender_address: data.sender_address,
            recipient_fn: data.recipient_fn,
            recipient_phone: data.recipient_phone,
            recipient_address: data.recipient_address,
            customer: data.customer,
            invoice: data.invoice,
            products: [
              {
                product_name: "",
                product_price: null,
              },
            ],
          };
        } else {
          this.$toasted.error("Không tìm thấy dữ liệu phù hợp");
        
        }
      })
      .catch((err) => {
         this.$toasted.error("Không tìm thấy dữ liệu phù hợp");
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form @submit.prevent="createNewOrder(form)" id="form-data-product">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-3" v-if="form">
                <p class="card-title-desc">Chỉnh sửa thông tin nếu cần</p>

                <div class="mb-3">
                  <label>Tên người gửi</label>
                  <input
                    v-model="form.sender_fn"
                    type="text"
                    class="form-control"
                    placeholder="Type something"
                    name="sender_fn"
                  />
                </div>
                <div class="mb-3">
                  <label>Số điện thoại người gửi</label>
                  <div>
                    <input
                      v-model="form.sender_phone"
                      type="text"
                      name="sender_phone"
                      class="form-control"
                      placeholder="Enter only digits"
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <label>E-Mail người gửi</label>
                  <div>
                    <input
                      v-model="form.sender_email"
                      type="email"
                      name="sender_email"
                      class="form-control"
                      placeholder="Nhập địa chỉ e-mail"
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <label>Địa chỉ người gửi</label>
                  <div>
                    <input
                      v-model="form.sender_address"
                      type="text"
                      name="sender_address"
                      class="form-control"
                      placeholder="Nhập địa chỉ người gửi"
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <label>Tên người nhận</label>
                  <input
                    v-model="form.recipient_fn"
                    type="text"
                    class="form-control"
                    placeholder="Nhập tên người nhận"
                    name="recipient_fn"
                  />
                </div>
                <div class="mb-3">
                  <label>Số điện thoại người nhận</label>
                  <div>
                    <input
                      v-model="form.recipient_phone"
                      type="text"
                      name="recipient_phone"
                      class="form-control"
                      placeholder="Nhập số điện thoại người nhận"
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <label>E-Mail người nhận</label>
                  <div>
                    <input
                      v-model="form.recipient_email"
                      type="email"
                      name="recipient_email"
                      class="form-control"
                      placeholder="Nhập địa chỉ e-mail người nhận"
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <label>Địa chỉ người nhận</label>
                  <div>
                    <input
                      v-model="form.recipient_address"
                      type="text"
                      name="recipient_address"
                      class="form-control"
                      placeholder="Nhập địa chỉ người nhận"
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <div class="my-2">
                    <button
                      type="button"
                      @click="addProduct()"
                      class="btn btn-sm btn-primary"
                    >
                      Thêm dữ liệu
                    </button>
                  </div>

                  <div
                    class="row mb-3"
                    v-for="(item, index) in form.products"
                    :key="index"
                  >
                    <div class="col">
                      <input
                        type="text"
                        v-model="item.product_name"
                        class="form-control"
                        placeholder="Nhập tên sản phẩm"
                      />
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        v-model="item.product_price"
                        class="form-control"
                        placeholder="Nhập giá trị ước tính"
                      />
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <button type="submit" class="btn btn-info">
                    Tạo đơn mới
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- end row -->
  </Layout>
</template>



